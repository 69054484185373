<template>
  <div class="bac">
    <div style="text-align: center">
      <el-card shadow="hover" style="width: 12rem">
        <el-table
            :data="projects"
            highlight-current-row
            max-height="700"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column label="项目名称" prop="projectName"></el-table-column>
          <el-table-column label="发布时间" prop="publishTime"></el-table-column>
          <el-table-column label="设计领域" prop="typeSecond" ></el-table-column>
          <el-table-column label="操作" prop="" >
            <template #default="scope">
              <el-button @click="downloadFile(scope.row.id)">下载</el-button>
              <el-button type="primary" @click="pass(scope.row.id)">通过</el-button>
              <el-button type="danger" @click="reject(scope.row.id)">拒绝</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractAuditing",
  data(){
    return{
      pageInfo:{
        count:0,
        pageSize:20,
        currentPage:1,
      },
      subForm:{
        pid:1,
        reason:'',
      },
      projects:[],
      total: 0,
      positionTemp:[],
    }
  },
  mounted() {
    this.getInf()
  },
  methods:{
    getInf() {
      this.$http({
        method: 'post',
        url: '/allHavePendingReviewContractProjects',
        data: this.pageInfo,
      }).then(res => {
        this.projects = res.data.data;
      })
    },
    downloadFile(index){
      this.subForm.pid = index;
      this.$http({
        method: 'post',
        url: '/downloadContractProject',
        data: this.subForm,
      }).then(res => {
        if (res.data.code === 1){
          window.location.href = res.data.url;
        }
      })
    },
    pass(index){
      this.subForm.pid = index;
      this.$http({
        method: 'post',
        url: '/admin/agreeContract',
        data: this.subForm,
      }).then(res => {
      })
    },
    reject(index){
      this.subForm.pid = index;
      this.$http({
        method: 'post',
        url: '/admin/refuseContract',
        data: this.subForm,
      }).then(res => {
      })
    }
  }
}
</script>

<style scoped>
.bac{
  height: 9.5rem;
  width: 16rem;
}
</style>